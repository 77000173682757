import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import sectionData from './data.json'
import GroupForm from '../../components/groupform'
import Fuel from '../../components/fuel'
import Purchases from './purchases'
import PurchasesSelector from './purchasesSelector'

import './footprint.css'
import { Trans, useTranslation } from 'react-i18next'

function renderSubForm(step, selectedFuels, setSelectedFuels, setSteps) {
  switch(step) {
    case 'fuels':
      return (
          <Fuel
            selectedFuels={selectedFuels}
            setSelectedFuels={setSelectedFuels}
            name="fuels"
            prefix="footprint.fuels"
          />
      )
    case 'energy':
      return <GroupForm data={sectionData.sections.energy} name="energy" />
    case 'purchases-part1':
      return <GroupForm data={sectionData.sections['purchases-part1']} name="part1" />
    case 'purchases-part2':
      return (
        <Purchases 
          data={sectionData.sections['purchases-part2']}
          name="part2"
          setSelectedFuels={setSelectedFuels}
          selectedFuels={selectedFuels}
        />
      )
    case 'purchases':
      return <PurchasesSelector setSteps={setSteps} />
    default:
      return <Fuel 
        selectedFuels={selectedFuels} 
        setSelectedFuels={setSelectedFuels} 
      />
  }
}

function Footprint(props) {
  const { t } = useTranslation()
  const {
    activeStep,
    selectedFuels,
    setSelectedFuels,
    setSteps
  } = props

  useEffect(() => {
    if(window.ga) {
      const stepNames = {
        energy: 'Ostettu energia',
        purchases: 'Epäsuorat päästöt',
        fuels: 'Omat suorat päästöt',
        'purchases-part1': 'Hankinnat ja investoinnit - Euro',
        'purchases-part2': 'Hankinnat ja investoinnit - Yksikkö'

      }
      const title = stepNames[activeStep] ?? 'Jalanjälki'
      window.ga.pageView('/ilmastolaskuri', title)
    }
  },[activeStep])

  return (
    <>
      <div className="section" id="footprint">
        <div className="group info">
          <div className="group-content">
            <h2>{t(`footprint:info.titles.${activeStep}`)}</h2>
            <p>
              <Trans i18nKey={`footprint:info.${activeStep}`} >
                <a target="_blank" rel="noopener noreferrer" href="https://ghgprotocol.org/standards">GHG-protocol</a> 
              </Trans>
            </p>
          </div>
        </div>
        {
          renderSubForm(activeStep, selectedFuels, setSelectedFuels, setSteps)
        }
      </div>
    </>
  )
}

Footprint.propTypes = {
  activeStep: PropTypes.string,
  selectedFuels: PropTypes.object,
  setSelectedFuels: PropTypes.func.isRequired,
  setSteps: PropTypes.func.isRequired
}

export default Footprint