import { useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { PlusCircle, X } from 'react-feather'
import { Group } from '../../components/groupform'
import UnitInput from '../../components/UnitInput'
import FuelModal from '../../sections/footprint/fuel-modal'
import { useTranslation } from 'react-i18next'


function FuelPurchases(props) {
  const {setFieldValue} = useFormikContext()
  const {t} = useTranslation()

  const [showFuelModal, setShowFuelModal] = useState(false)
  const {
    selectedFuels,
    setSelectedFuels,
    name,
    title,
    prefix
  } = props

  const handleDelete = fuel => {
    setSelectedFuels({
      ...selectedFuels,
      [name]: selectedFuels[name].filter(value => value !== fuel)
    })
    setFieldValue(`${prefix}.${fuel}`, '')
  }

  return (
    <>
      {showFuelModal && (
        <FuelModal
          selectedFuels={selectedFuels[name]}
          close={() => {setShowFuelModal(false)}}
          select={(fuels) => {
            setSelectedFuels({
              ...selectedFuels,
              [name]: fuels
            })
            setShowFuelModal(false)
          }}
          />
      )}
      <div className="group fuels">
        <div className="group-header">
          <h2>{t(title)}</h2>
        </div>
        <div className="group-content">
          <div className="fuel">
            <UnitInput
              name={`${prefix}.electricity`}
              label={t('common:fuel.electricity')}
              unit="kWh"
            />
          </div>
          <div className="add-fuel">
            <h3>{t('common:fuel.add-title')}</h3>
            <button type="button" onClick={() => {setShowFuelModal(true)}} > <PlusCircle/> {t('common:fuel.add')}</button>
          </div>
          {
            selectedFuels[name].map(selectedFuel => {
              const label = `${t(`common:fuel.${selectedFuel}`)}`
              return (
                <div className="fuel" key={selectedFuel}>
                  <UnitInput
                      name={`${prefix}.${selectedFuel}`}
                      label={label}
                      unit={selectedFuel === 'natural-gas' ? t('common:fuel.cubicUnit') : t('common:fuel.tons')}
                  />
                  <span className="delete" onClick={() => {handleDelete(selectedFuel)}}><X /></span>
                </div>
              )
            })
          }
        </div>
      </div>
    </>
  )
}


function Purchases(props) {
  const {
    data,
    selectedFuels,
    setSelectedFuels,
    name,
  } = props
  return (
    <>
      {
        data.groups.map(group => {
          if(group.type === 'fuel') {
            return (
              <FuelPurchases
                selectedFuels={selectedFuels}
                setSelectedFuels={setSelectedFuels}
                name='purchases'
                prefix={group.prefix}
                title={group.title}
                key={`${name}-${group.name}`}
              />
            )
          } else {
            return (<Group group={group} name={name} key={`${name}-${group.name}`}/>)
          }
        })
      }
    </>
  )
}

Purchases.propTypes = {
  data: PropTypes.shape({
    groups: PropTypes.arrayOf(PropTypes.object)
  }).isRequired,
  selectedFuels: PropTypes.object,
  setSelectedFuels: PropTypes.func.isRequired,
  name: PropTypes.string
}

export default Purchases