const initialValues = {
  employment: {
    "company": {
      "personnel": '',
      "corporate-tax": '',
      "income-tax": ''
    },
    "purchases": {
      materials: {
        electronics: "",
        mining: "",
        chemistry: "",
        "metal-and-machines": "",
        metal: "",
        forestry: "",
        other: "",
      },
      services: {
        energy: "",
        admin: "",
        maintenance: "",
        sanitation: "",
        "business-travel": "",
        finance: "",
        construction: "",
        planning: "",
        communication: "",
        health: "",
        it: "",
        storage: "",
      },
      logistics: {
        air: "",
        road: "",
        rail: "",
        water: "",
      },
    },
  },
}

export default initialValues