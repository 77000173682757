import React, { useEffect, useState, useRef } from 'react'
import { Form, Formik } from 'formik'
import schema from './schema'
import initialValues from './initialValues'
import LeadingQuestionsSection from '../sections/leading-questions'
import FootprintSection from '../sections/footprint'
import EmploymentSection from '../sections/employment'
import CircularEconomySection, {circularEconomySectionData} from '../sections/circular-economy'
import CompetenceAndInnovationSection, {competenceSectionData}from '../sections/competence-and-innovation'
import WellnessAndEqualitySection, {wellnessSectionData} from '../sections/wellness-and-equality'
import HealthAndSafetySection, {healthSectionData} from '../sections/health-and-safety'
import HandprintSection from '../sections/handprint'
import Header from '../components/header'
import Nav from '../components/nav'
import ResultsPage from '../pages/results'
import ResetModal from '../components/Modal'

import './form.css'
import { useTranslation } from 'react-i18next'
import IntroSection from '../sections/intro'

function renderStep(step, selectedFuels, setSelectedFuels, setSolutions, solutions, steps, setSteps) {
  const [mainStep, subStep] = step.name.split('.')
  switch(mainStep) {
    case 'intro':
      return <IntroSection />
    case 'leading': 
      return <LeadingQuestionsSection 
        setSteps={(step) => setSteps(steps, step)} 
      />
    case 'footprint':
    case 'purchases':
      return <FootprintSection 
        selectedFuels={selectedFuels} 
        setSelectedFuels={setSelectedFuels} 
        activeStep={subStep}
        setSteps={(step) => setSteps(steps,step)}
      />
    case 'employment':
      return <EmploymentSection />
    case 'circularEconomy':
      return <CircularEconomySection />
    case 'competence':
      return <CompetenceAndInnovationSection />
    case 'wellness': 
      return <WellnessAndEqualitySection />
    case 'health':
      return <HealthAndSafetySection />
    case 'handprint':
      return <HandprintSection 
        solutions={solutions} 
        setSolutions={setSolutions} 
      />
    default:
      return <LeadingQuestionsSection 
        setSteps={(step) => setSteps(steps, step)} 
      />
    
  }
}

const stepData = [
  {name: 'intro', order: 1},
  {name: 'leading', order: 2},
  {name: 'footprint.fuels', order: 3},
  {name: 'footprint.energy', order: 4},
  {name: 'footprint.purchases', order: 5},
  {name: 'purchases.purchases-part1', order: 6},
  {name: 'purchases.purchases-part2', order: 7},
  {name: 'handprint', order: 8},
  {name: 'employment', order: 9},
  {name: 'circularEconomy', order: 10},
  {name: 'competence', order: 11},
  {name: 'wellness', order: 12},
  {name: 'health', order: 13},
  {name: 'results', order: 14},
]

const initialSteps = [
  {name: 'intro', order: 1},
  {name: 'leading', order: 2},
]

function ClimateCalculatorForm() {
  const formRef = useRef()
  const [activeStep, setActiveStep] = useState(0)
  const [openNav, setOpenNav] = useState(false)
  const [showResetModal, setShowResetModal] = useState(false)
  const [steps, setSteps ]  = useState(initialSteps)
  const [results, setResults] = useState({})
  const [formData, setFormData] = useState({})
  const [solutions, setSolutions] = useState([])
  const [selectedFuels, setSelectedFuels] = useState({
    fuels: [],
    purchases: []
  })
  const { t } = useTranslation()

  const stepModifier = steps.findIndex(step => step.name === 'results') > -1 ? 2 : 1
  const isLastStep = activeStep + stepModifier === steps.length
  const stepValidationSchema = schema

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])

  const alertUser = e => {
    e.preventDefault()
    e.returnValue = ''
  }

  //when changing a "page" move the user to the top
  useEffect(() => {
    document.querySelector('.content').scrollTo(0,0)
  }, [activeStep])

  const setOrderedSteps = (steps, step) => {
    let newSteps = [...steps]
    if(steps.findIndex(item => item.name.includes(step)) !== -1) {
      newSteps.filter(item => item.name.includes(step)).forEach(item => resetForm(item))
      newSteps = newSteps.filter(item => !item.name.includes(step))

      if(step === 'footprint') {
        newSteps.filter(item => item.name.includes('purchases.')).forEach(item => resetForm(item))
        newSteps = newSteps.filter(item => !item.name.includes('purchases.'))

      }
    }else {
      if(step === 'footprint') {
        stepData.forEach(s => {
          if(s.name.includes('footprint')) {
            newSteps.push(s)
          }
        })
      } else if (step.includes('purchases.')) {
        newSteps.filter(item => item.name.includes('purchases.')).forEach(item => resetForm(item))
        newSteps = newSteps.filter(item => !item.name.includes('purchases.'))
        newSteps.push(stepData.find(item => item.name === step))
      } else {
        newSteps.push(stepData.find(item => item.name === step))
      }
    }
    newSteps.sort((a,b) => a.order-b.order)
    setSteps(newSteps)
  }

  const resetSteps = () => {
    setSteps(initialSteps)
  }

  const navigateToStep = step => {
    if(steps[step].name === 'results' && formRef.current) {
      setFormData(formRef.current.values)
    }
    setActiveStep(step)
  }

  const resetForm = section => {
    switch(section.name) {
      case 'basics':
        if(formRef.current) {
          formRef.current.setFieldValue('basics', initialValues.basics)
        }
        break
      case 'employment':
        if(formRef.current) {
          formRef.current.setFieldValue('employment', initialValues.employment)
        }
        break
      case 'leading':
        if(formRef.current) {
          formRef.current.setFieldValue('leading', {})
        }
        resetSteps()
        break
      case 'footprint.fuels':
      case 'footprint.energy': 
      case 'footprint.purchases':
      case 'purchases.purchases-part1':
      case 'purchases.purchases-part2': 
        //eslint-disable-next-line no-unused-vars
        const [_, subStep] = section.name.split('.')
        if(subStep === 'fuels') {
          setSelectedFuels({
            ...selectedFuels,
            fuels: []
          })
          if(formRef.current) {
            formRef.current.setFieldValue(`footprint.${subStep}`, initialValues.footprint[subStep])
          }
        } 
        else if(subStep === 'purchases') {
            formRef.current.setFieldValue(`footprint.purchases.selector`, '')
        }
        else {
          if(subStep === 'purchases-part2') {
            setSelectedFuels({
              ...selectedFuels,
              purchases: []
            })
          }
          if(formRef.current) {
            formRef.current.setFieldValue(`footprint.purchases.purchases-part1`, initialValues.footprint.purchases['purchases-part1'])
            formRef.current.setFieldValue(`footprint.purchases.purchases-part2`, initialValues.footprint.purchases['purchases-part2'])
          }
        }
        break
      case 'handprint':
        setSolutions([])
        break
      case 'circularEconomy':
        if(formRef.current) {
          circularEconomySectionData.groups.forEach(group => {
            group.fields.forEach(field => formRef.current.setFieldValue(field.name, ''))
          })
        }
        break
      case 'health':
        if(formRef.current) {
          healthSectionData.groups.forEach(group => {
            group.fields.forEach(field => formRef.current.setFieldValue(field.name, ''))
          })
        }
        break
      case 'wellness':
        if(formRef.current) {
          wellnessSectionData.groups.forEach(group => {
            group.fields.forEach(field => formRef.current.setFieldValue(field.name, ''))
          })
        }
        break
      case 'competence':
        if(formRef.current) {
          competenceSectionData.groups.forEach(group => {
            group.fields.forEach(field => formRef.current.setFieldValue(field.name, ''))
          })
        }
        break
      default:
        break
    }
  }

  return (
    <>
      {
        //TODO: Make a reset modal
        showResetModal && (
          <ResetModal
            title={t('common:resetForm.title')}
            close={() => {setShowResetModal(false)}}
            confirm={(reset) => {
              if(reset === 'all') {
                setFormData({})
                if(formRef.current) {
                  formRef.current.resetForm()
                }
                setSelectedFuels({
                  fuels: [],
                  purchases: []
                })
                setSteps(initialSteps)
                setActiveStep(0)
              }
              else {
                resetForm(steps[activeStep])
              }
              setShowResetModal(false)
            }}
          />
        )
      }
      <div className={`container${openNav ? ' open': ''}`}>

        <Nav
          steps={steps} 
          open={openNav}
          activeStep={activeStep}
          setActiveStep={navigateToStep}
          setOpenNav={setOpenNav}
        />
        <div className="content">
          <Header 
            activeStep={steps[activeStep]}
            steps={steps}
            openNav={setOpenNav}
            navToggle={openNav}
          />
          {
            steps[activeStep].name === 'results' ? (
              <ResultsPage results={results} returnToForm={() => setActiveStep(activeStep-1)}/>
            ) :
            (
              <Formik
                innerRef={formRef}
                initialValues={{
                  ...initialValues,
                  ...formData
                }}
                validationSchema={stepValidationSchema}
                validateOnChange={true}
                onSubmit={async (values, actions) => {
                  if (isLastStep) {
                    if(steps.findIndex(step => step.name === 'results') === -1) {
                      setOrderedSteps(steps, 'results')
                    }
                    setActiveStep(activeStep+1)
                    setFormData(values)
                    if(window.ga) {
                      window.ga.event('submit', 'Tulosten laskenta', 'Lomakkeet')
                    }
                    const response = await fetch('/calculate', {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({...values, handprint: solutions})
                    })
                    if (response.status === 200) {
                      const results = await response.json()
                      setResults(results)
                    }
                  }
                  else {
                    setActiveStep(activeStep+1)
                    actions.setTouched({})
                    actions.setSubmitting(false)
                  }
                }}
              >
                <Form>
                  {
                    renderStep(steps[activeStep],
                      selectedFuels,
                      setSelectedFuels,
                      setSolutions,
                      solutions,
                      steps,
                      setOrderedSteps
                    )
                  }
                  <div className="form-footer">
                    <div className="footer-content">
                      <button type="button" onClick={() => setActiveStep(activeStep-1)} disabled={activeStep === 0}>{t('common:previous')}</button>
                      <button type="submit" className="primary">{ isLastStep ? t('common:showResults') : t('common:next')}</button>
                    </div>
                </div>
                <div className="reset-container">
                  <button className="reset" type="button" onClick={() => setShowResetModal(true)} >{t('common:emptyForm')}</button>
                </div>
                </Form>
              </Formik>
            )
          }
          <div className="overlay" onClick={() => setOpenNav(false)}></div>
        </div>
      </div>
    </>
  )
}

export default ClimateCalculatorForm

