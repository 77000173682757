import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom" 
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent"
import ClimateCalculatorForm from '../form'
import Frontpage from '../pages/frontpage'
import { useTranslation } from 'react-i18next'
import { initialize } from '../ga'

const GA_ID = 'G-V42P425YYP'

function App() {
  const { t } = useTranslation()
  useEffect(() => {
    if(getCookieConsentValue() === 'true') {
      initialize(GA_ID)
    }
  }, [])

  return (
    <>
      <Router>
        <Switch>
          <Route path="/ilmastolaskuri">
            <ClimateCalculatorForm/>
          </Route>
          <Route path='/'>
            <Frontpage/>
          </Route>
        </Switch>
      </Router>
      <CookieConsent
        enableDeclineButton
        buttonWrapperClasses={['cookie-buttons']}
        declineButtonText={t('common:cookies.reject')}
        onAccept={() => initialize(GA_ID)}
        buttonText={t('common:cookies.accept')}
        disableStyles={true}
        containerClasses={'cookie'}
        location={'none'}
      >
        <h2>{t('common:cookies.title')}</h2>
        <p>
          {t('common:cookies.content')}
          <a target="_blank" rel="noopener noreferrer" href={t('common:cookies.link')}>{t('common:cookies.readMore')}</a>
        </p>
      </CookieConsent>
    </>
  )
}

export default App
