import * as Yup from 'yup'
import { positiveNumber, positivePercentage } from '../../common/schema-helpers'


export default Yup.object().shape({
  fuels: Yup.object().shape({
    gasoline: positiveNumber,
    "light-oil": positiveNumber,
    diesel: positiveNumber,
    "heavy-oil": positiveNumber,
    coal:positiveNumber,
    "natural-gas": positiveNumber,
    "liquified-natural-gas": positiveNumber,
    pellets: positiveNumber,
    wood: positiveNumber,
    "other-sedimentary-fuels": positiveNumber,
    biogas: positiveNumber,
    biofuel: positiveNumber,
    "recycled-fuels": positiveNumber,
    "other-mixed-fuels": positiveNumber
  }),
  purchases: Yup.object().shape({
    energy: Yup.object().shape({
      electricity: Yup.object().shape({
        value: positiveNumber,
        percent: positivePercentage,
        multiplier: positiveNumber
      }),
      heating: Yup.object().shape({
        value: positiveNumber,
        percent: positivePercentage,
        multiplier: positiveNumber
      }),
      cooling: Yup.object().shape({
        value: positiveNumber,
        percent: positivePercentage,
        multiplier: positiveNumber
      }),
      steam: Yup.object().shape({
        value: positiveNumber,
        multiplier: positiveNumber
      })
    }),
    'purchases-part1': Yup.object().shape({
      materials: Yup.object().shape({
        electronics: positiveNumber,
        mining: positiveNumber,
        chemistry: positiveNumber,
        "metal-and-machines": positiveNumber,
        metal: positiveNumber,
        forestry: positiveNumber,
        other: positiveNumber
      }),
      services: Yup.object().shape({
        energy: positiveNumber,
        admin: positiveNumber,
        maintenance: positiveNumber,
        sanitation: positiveNumber,
        "business-travel": positiveNumber,
        finance: positiveNumber,
        construction: positiveNumber,
        planning: positiveNumber,
        communication: positiveNumber,
        health: positiveNumber,
        it: positiveNumber,
        storage: positiveNumber
      }),
      logistics: Yup.object().shape({
        air: positiveNumber,
        road: positiveNumber,
        rail: positiveNumber,
        water: positiveNumber
      })
    }),
    'purchases-part2': Yup.object().shape({
      materials: Yup.object().shape({
        aluminum: positiveNumber,
        steel: positiveNumber,
        copper: positiveNumber,
        glass: positiveNumber,
        plastic: positiveNumber,
        concrete: positiveNumber
      }),
      logistics: Yup.object().shape({
        van: positiveNumber,
        truck: positiveNumber,
        "semi-trailer": positiveNumber,
        trailer: positiveNumber,
        rails: positiveNumber,
        "container-ship": positiveNumber,
        "roro-ship": positiveNumber,
        aviation: Yup.object().shape({
          domestic: positiveNumber,
          foreign: positiveNumber,
          "long-haul": positiveNumber
        })
      }),
      "corporate-travel": Yup.object().shape({
        aviation: Yup.object().shape({
          domestic: positiveNumber,
          foreign: positiveNumber,
          "long-haul": positiveNumber
        }),
        car: positiveNumber,
        bus: positiveNumber,
        "foreign-train": positiveNumber,
        ship: positiveNumber,
        hotel: positiveNumber,
      }),
      commuting: Yup.object().shape({
        personnel: positiveNumber,
        "work-days": positiveNumber,
        remote: positiveNumber,
        car: positiveNumber,
        "car-estimate": positiveNumber,
        bus: positiveNumber,
        "bus-estimate": positiveNumber,
        emissionless: positiveNumber,
        "emissionless-estimate": positiveNumber
      }),
      sanitation: Yup.object().shape({
        landfill: positiveNumber,
        burning: positiveNumber,
        bio: positiveNumber,
        paper: positiveNumber,
        cardboard: positiveNumber,
        glass: positiveNumber,
        metal: positiveNumber,
        plastic: positiveNumber,
        wood: positiveNumber,
        energy: positiveNumber,
        construction: positiveNumber,
        ser: positiveNumber,
        dangerous: positiveNumber,
        wastewater: positiveNumber
      }),
      processing: Yup.object().shape({
        electricity: positiveNumber
      }),
      usage: Yup.object().shape({
        electricity: positiveNumber,
        gasoline: positiveNumber,
        "light-oil": positiveNumber,
        diesel: positiveNumber,
        "heavy-oil": positiveNumber,
        coal:positiveNumber,
        "natural-gas": positiveNumber,
        "liquified-natural-gas": positiveNumber,
        pellets: positiveNumber,
        wood: positiveNumber,
        "other-sedimentary-fuels": positiveNumber,
        biogas: positiveNumber,
        biofuel: positiveNumber,
        "recycled-fuels": positiveNumber,
        "other-mixed-fuels": positiveNumber
      }),
      disposal: Yup.object().shape({
        landfill: positiveNumber,
        burning: positiveNumber,
        bio: positiveNumber,
        paper: positiveNumber,
        cardboard: positiveNumber,
        glass: positiveNumber,
        metal: positiveNumber,
        plastic: positiveNumber,
        wood: positiveNumber,
        energy: positiveNumber,
        construction: positiveNumber,
        ser: positiveNumber,
        dangerous: positiveNumber
      })
    })
  })
})