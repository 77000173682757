import React from 'react'
import { ErrorMessage, Field, getIn, useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Tooltip from '../Tooltip'

function UnitInput(props) {
  const { t } = useTranslation()
  const { errors } = useFormikContext()
  const {
    name,
    label,
    unit,
    tooltip
  } = props

  return (
    <div className="unit-input">
      <label htmlFor={name}>
        {label}
        { tooltip && <Tooltip id={name} tooltip={tooltip} /> }
      </label>
      <div className={`unit-input-container${getIn(errors, name) ? ' errors': ''}`}>
      <Field type="text" name={name} id={name} maxLength="35"/>
      {
        unit && (
          <span className="unit">{unit}</span>
        )
      }
      </div>
      <ErrorMessage name={name} >{msg => <div className="errors">{t(msg)}</div>}</ErrorMessage>
    </div>
  )
}
UnitInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  unit: PropTypes.string,
  tooltip: PropTypes.string,
}
export default UnitInput