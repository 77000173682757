import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'

function ConfirmModal(props) {
  const {
    title,
    close,
    confirm
  } = props

  const [resetType, setResetType] = useState('one')
  const { t } = useTranslation()
  const handleChange = event => {
    setResetType(event.target.value)
  }

  return (
    <div id="modal" className="modal">
      <div className="modal-container">
        <div className="modal-header">
          <h2>{title}</h2>
          <button type="button" className="close" onClick={close} ><X /></button>
        </div>
        <div className="modal-content">
          <label htmlFor="resetOne">
            <input type="radio" id="resetOne"  name="reset" value="one" checked={resetType === 'one'} onChange={handleChange}></input>
            <span>{t('common:reset.one')}</span>
          </label>
          <label htmlFor="resetAll">
            <input type="radio" id="resetAll" name="reset" value="all" checked={resetType === 'all'} onChange={handleChange}></input>
            <span>{t('common:reset.all')}</span>
          </label>
        </div>
        <div className="modal-footer">
          <button type="button" className="cancel" onClick={close}>{t('common:cancel')}</button>
          <button type="button" className="primary" onClick={() => confirm(resetType)}>{t('common:reset.confirm')}</button>
        </div>
      </div>
    </div>
  )
}

ConfirmModal.propTypes = {
  title: PropTypes.string,
  close: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,

}
export default ConfirmModal