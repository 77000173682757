import React, { useEffect } from 'react'
import sectionData from './data.json'
import GroupForm from '../../components/groupform'
import { Trans, useTranslation } from 'react-i18next'



function CircularEconomy() {
  const { t } = useTranslation()
  useEffect(() => {
    if(window.ga) {
      window.ga.pageView('/ilmastolaskuri', 'Kiertotalous')
    }
  },[])

  return (
    <>
      <div className="section">
        <div className="group info">
          <div className="group-content">
            <h2>{t('circularEconomy:info.title')}</h2>
            <Trans i18nKey={`circularEconomy:info.text`} />
          </div>
        </div>
        <GroupForm data={sectionData} name="circularEconomy" />
      </div>
    </>
  )
}

export default CircularEconomy

export {
   sectionData as circularEconomySectionData
}