const replacementData = {
  fuel: [
    {value: 'gasoline', unit: 'ton'},
    {value: 'light-oil', unit: 'ton'},
    {value: 'diesel', unit: 'ton'},
    {value: 'heavy-oil', unit: 'ton'},
    {value: 'coal', unit: 'ton'},
    {value: 'natural-gas', unit: 'ton'},
    {value: 'liquified-natural-gas', unit: 'ton'},
    {value: 'pellets', unit: 'ton'},
    {value: 'wood', unit: 'ton'},
    {value: 'other-sedimentary-fuels', unit: 'ton'},
    {value: 'biogas',  unit: 'ton'},
    {value: 'biofuel', unit: 'ton'},
    {value: 'recycled-fuels', unit: 'ton'},
    {value: 'other-mixed-fuels', unit: 'ton'},
  ]
,
  energy: [
    {value:'electricity', unit: 'kWh'},
    {value:'heating', unit: 'kWh'},
    {value:'cooling', unit: 'kWh'},
  ],
  materials: [
    {value:'aluminum', unit: 'ton'},
    {value:'copper', unit: 'ton'},
    {value:'steel', unit: 'ton'},
    {value:'glass', unit: 'ton'},
    {value:'plastic', unit: 'ton'},
    {value:'concrete', unit: 'ton'},
  ],
  travel: [
    {value:'aviation.domestic', unit: 'km'},
    {value:'aviation.foreign', unit: 'km'},
    {value:'aviation.long-haul', unit: 'km'},
    {value:'car', unit: 'km'},
    {value:'bus', unit: 'km'},
    {value:'foreing-train', unit: 'km'},
    {value:'ship', unit: 'km' },
    {value:'hotel', unit: 'kpl'},
  ],
  purchases: [
    {value:'materials.electronics', unit: 'k€'},
    {value:'materials.mining', unit: 'k€'},
    {value:'materials.chemistry', unit: 'k€'},
    {value:'materials.machinery', unit: 'k€'},
    {value:'materials.metal', unit: 'k€'},
    {value:'materials.forestry', unit: 'k€'},
    {value:'materials.other', unit: 'k€'},
    {value:'materials.furniture', unit: 'k€'},
    {value:'materials.food', unit: 'k€'},
    {value:'materials.textile', unit: 'k€'},
    {value:'services.energy', unit: 'k€'},
    {value:'services.admin', unit: 'k€'},
    {value:'services.maintenance', unit: 'k€'},
    {value:'services.sanitation', unit: 'k€'},
    {value:'services.business-travel', unit: 'k€'},
    {value:'services.finance', unit: 'k€'},
    {value:'services.construction', unit: 'k€'},
    {value:'services.planning', unit: 'k€'},
    {value:'services.communication', unit: 'k€'},
    {value:'services.health', unit: 'k€'},
    {value:'services.it', unit: 'k€'},
    {value:'services.storage', unit: 'k€'},
    {value:'services.personnel', unit: 'k€'},
    {value:'services.store', unit: 'k€'},
    {value:'services.accommodation', unit: 'k€'},
    {value:'services.food', unit: 'k€'},
    {value:'services.office', unit: 'k€'},
    {value:'services.entertainment', unit: 'k€'},
    {value:'logistics.air', unit: 'k€'},
    {value:'logistics.road', unit: 'k€'},
    {value:'logistics.rails', unit: 'k€'},
    {value:'logistics.water', unit: 'k€'},
  ]
}

export default replacementData