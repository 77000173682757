import React, { useEffect } from 'react'
import './style.css'
import Logo from '../../common/logo.svg'
import HeroLarge from './Climpactor_hero_rajattu_02.jpg'
import LanguageSelector from '../../components/languageSelector'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function Frontpage(props) {
  const { t } = useTranslation()
  useEffect(() => {
    document.documentElement.classList.add('frontpage')
    return () => {
      document.documentElement.classList.remove('frontpage')
    }
  })

  useEffect(() => {
    if(window.ga) {
      window.ga.pageView('/', 'Etusivu')
    }
  }, [])

  return (
    <div className="container">
      <div className="frontpage-content">

        <div className="main">
          <header>
            <div className="frontpage-container">
              <div className="logo">
                <img src={Logo} alt="climpactor logo" />
              </div>
              <LanguageSelector />
            </div>
          </header>
          <div className="hero-container">
            <div className="hero">
              <h1>{t('common:frontPage.title')}</h1>
              <p>
                {t('common:frontPage.heroText')}
              </p>
              <Link to="/ilmastolaskuri">{t('common:frontPage.begin')}</Link>
            </div>
          </div>
        </div>
        <div className="side">
          <div className="hero-image">
            <img src={HeroLarge} alt="henkilö pyöräilee suolla, taustalla on tuulivoimaloita"/>
          </div>
        </div>
      </div>
      <footer>
          <ul>
            <li><a target="_blank" rel="noopener noreferrer" href="https://teknologiateollisuus.fi/fi/yhteystiedot?field_unit=17895&search_api_fulltext=">{t('common:frontPage.contact')}</a></li>
            <li><a target="_blank" rel="noopener noreferrer" href={t('common:frontPage.privacyLink')}>{t('common:frontPage.privacy')}</a></li>
          </ul>
          <div className="copy">
            &copy; {new Date().getFullYear()} Teknologiateollisuus ry.
          </div>
      </footer>
    </div>
  )
}


export default Frontpage
