import React, { useRef, useState } from 'react'
import {Doughnut} from 'react-chartjs-2'


function CustomDoughtnut(props) {
  const ref = useRef(null)
  const [legend, setLegend] = useState(false)
  const {subtitle, ...rest} = props

  const options = {
    cutoutPercentage: 85,
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    text: {
      font: 'sans-serif'
    },
  }


  if(subtitle) {
    options.subtitle = subtitle
  }

  const plugins = [{
    afterDatasetsDraw: function() {
      if (!legend) {
        setLegend(true)
      }
    }
  },
  {
    beforeDraw: function (chart) {
      const {
        width,
        height,
        ctx
      } = chart

      const emission = chart.config.options.subtitle

      const {
        font = 'FFDINWebPro,Verdana,Helvetica,sans-serif',
      }  = chart.config.options.text || {}
      const text = Math.round(chart.config.data.datasets[0].data.reduce((sum, value) => {
        return sum += value
      }, 0))

      ctx.font = `3.125rem ${font}`;
      ctx.textBaseline = "middle";
      ctx.fillStyle = "#000000"
      const textSize = ctx.measureText(text)
      const textX = Math.round((width - textSize.width) / 2)
      const textY = Math.round(height/2)-8

      ctx.fillText(text, textX, textY)
      ctx.save()

      ctx.font = `1rem ${font}`;
      ctx.textBaseline = "bottom";
      ctx.fillStyle = "#adadad"
      const lineHeight = 50
      const subtext = `Ton CO2 ${emission? emission.split(' ')[0]: ''}`
      const subtextX = Math.round((width - ctx.measureText(subtext).width) / 2)
      const subtextY = textY+lineHeight
      ctx.fillText(subtext,subtextX, subtextY)
      ctx.save()

      if(emission) {
        const emissionText = emission.split(' ')[1] ?? ''
        const emissionTextX = Math.round((width - ctx.measureText(emissionText).width) / 2)
        const emissionTextY = subtextY+16
        ctx.fillText(emissionText,emissionTextX, emissionTextY)
        ctx.save()
      }

    }
  },
  
]
  return (
    <>
      <div className="test">
        <Doughnut {...rest} plugins={plugins} ref={ref} options={options}/>
      </div>
      {
        legend && (
          <ul className="graph-legend">
            {
              ref.current.chartInstance.legend.legendItems.map(item => {
                return (
                  <li key={item.text}>
                    <div className="legend-icon" style={{backgroundColor: item.fillStyle}} />
                    {item.text}
                  </li>
                )
              })
            }
          </ul>
        )
      }
    </>
  )
}

export default CustomDoughtnut