import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { Info } from 'react-feather'

function Tooltip(props) {
  const { id, tooltip } = props
  return (
    <>
      <span className="tooltip-icon" data-tip data-for={id}><Info /></span>
      <ReactTooltip className="tooltip" id={id} place="left" effect="solid" arrowColor="transparent"
        overridePosition={({ left, top }, _e, _t, node) => {
          return {
            top: typeof node === 'string' ? top : Math.max(top, 16),
            left
          }
        }}
      >
        {tooltip}
      </ReactTooltip>
    </>
  )
}

Tooltip.propTypes = {
  tooltip: PropTypes.string,
  id: PropTypes.string.isRequired
}
export default Tooltip