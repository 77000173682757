import React, { useRef, useEffect, useState } from 'react'
import CustomDoughnut from './CustomDoughnut'
import CustomBar from './CustomBar'
import CustomStackBar from './CustomStackBar'
import UnitDoughnut from './UnitDoughnut'
import { ChevronDown, ChevronUp, FileText, ThumbsUp } from 'react-feather'
import { Trans, useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'
import './styles.css'
import TeknoEn from '../../common/teknologia_en.png'
import TeknoFi from '../../common/teknologia_fi.png'
import Gaia from '../../common/gaia.png'

const colors = [
  "#0acfcf",
  "#8a0fa6",
  "#ff805c",
  "#fd1db8",
  "#85e869",
  "#141f94",
  "#0f78b2",
  "#ff00b8",
  "#26ab9c"
]

function FootprintResults(props) {
  const { t } = useTranslation()
  const { data } = props
  const footprintOverallData = {
    labels: Object.keys(data.totals).map(key => t(`results:footprint.scopes.${key}`)),
    datasets: [{
      data: Object.values(data.totals),
      backgroundColor: colors
    }]
  }

  const sectionData = Object.entries(data.sections).flatMap(([section, values]) => {
    if(section === 'scope2') {
      return Object.entries(values).map(([subSection, subSectionData]) => ({
        name: `${section}-${subSection}`,
        data: {
          labels:[''],
          datasets: Object.entries(subSectionData).map(([key, value], idx) =>({
            label: `${t(`results:footprint.scope2.${key}`)} (${value})`,
            data: [value],
            backgroundColor: colors[idx],
            barPercentage: 0.4
          }))
        }
      }))
    }
    else {
      return  {
        name: section,
        data: {
          labels: [''],
          datasets: Object.entries(values).map(([key, value], idx) => ({
            label: `${t(`results:footprint.${key}`)} (${value})`,
            data: [value],
            backgroundColor: colors[idx],
            barPercentage: section === 'scope1'? 0.2 : 0.9
          }))
        }
      }
    }
  })

  return (
    <div className="group">
      <div className="group-header">
        <h2>{t('results:footprint.title')}</h2>
      </div>
      <div className="group-content graphs">
        <div className="graph-container">
          <div className="graph">
            <CustomDoughnut data={footprintOverallData} />
          </div>
        </div>
        {
          sectionData.map(section => {
            return (
              <div className="graph-container" key={section.name}>
                <div className="graph">
                  <h3>{t(`results:footprint.sections.${section.name}`)}</h3>
                  <CustomBar data={section.data} height={139} />
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

function HandprintResults(props) {
  const { t } = useTranslation()
  const { data } = props

  const handprintOverallData = {
    labels: data.totals.map((solution, idx) => `${t('results:handprint.solution')} ${idx+1}: ${solution.label}`),
    datasets: [{
      data: data.totals.map(solution => solution.value),
      backgroundColor: colors
    }]
  }

  const transformSolutionData = (data) => {
    const graphData = {
      labels: [''],
      datasets: [
        {
          label: `${t('results:handprint.before')} (${t(`results:handprint.resource.${data.original.name}`)})`,
          data: [data.original.amount],
          backgroundColor: "#909090",
          stack: 'stack 1',
          barPercentage: 0.2
        },
        {
          label: `${t('results:handprint.after')} (${t(`results:handprint.resource.${data.new.name}`)})`,
          data: [data.new.amount],
          backgroundColor: "#0acfcf",
          stack: 'stack 2',
          barPercentage: 0.2
        },
      ]
    }

    if(data.additional) {
      graphData.datasets.push(
        {
          label: `${t('results:handprint.after')} (${t(`results:handprint.resource.${data.additional.name}`)})`,
          data: [data.additional.amount],
          backgroundColor: "#8a0fa6",
          stack: 'stack 2',
          barPercentage: 0.2
        }
      )
    }
    return graphData
  }

  return (
    <div className="group">
      <div className="group-header">
        <h2>{t('results:handprint.title')}</h2>
      </div>
      <div className="group-content graphs">
        <div className="graph-container">
          <div className="graph">
            <CustomDoughnut data={handprintOverallData} subtitle={t('results:less-emissions')}/>
          </div>
        </div>
        {
          data.solutions.map((solution,idx) => {
            return (
              <div className="graph-container" key={solution.name}>
                <div className="graph">
                  <h3>{`${t('results:handprint.solution')} ${idx+1}: ${solution.name}`}</h3>
                  <CustomStackBar data={transformSolutionData(solution)} height={139} />
                </div>
              </div>
            )
          })

        }
      </div>
    </div>
  )
}

function EmploymentResults(props) {
  const { t } = useTranslation()
  const { data } = props

  const employmentData = {
    labels: [
      t('results:employment.company.employment'),
      t('results:employment.purchases.employment')
    ],
    datasets: [
      {
        data: [data.company.employment, data.purchases.employment],
        backgroundColor: colors,
      },
    ]
  }

  const separatedTaxData = {
    labels: [
      t('results:employment.company.corporateTax'),
      t('results:employment.company.incomeTax'),
      t('results:employment.purchases.corporateTax'),
      t('results:employment.purchases.incomeTax'),
    ],
    datasets: [{
        data: [
          data.company.corporateTax,
          data.company.incomeTax,
          data.purchases.corporateTax,
          data.purchases.incomeTax
        ],
        backgroundColor: [
          colors[0],
          'rgba(38,171,156,0.6)',
          colors[1],
          'rgba(138,21,166,0.7)',
        ]

    }]
  }

  return (
    <div className="group">
      <div className="group-header">
        <h2>{t('results:employment.title')}</h2>
      </div>
      <div className="group-content graphs">
        <div className="graph-container">
          <div className="graph">
            <h3>{t('results:employment.employment')}</h3>
            <UnitDoughnut round={false} data={employmentData} unit={(t('results:employment.unit'))} height={139} />
          </div>
        </div>
        <div className="graph-container">
            <h3>{t('results:employment.taxes')}</h3>
            <UnitDoughnut round={false} unit="k€" data={separatedTaxData} height={350} />
        </div>
      </div>
    </div>
  )
}

function renderMessage(t, translationKey, type, message) {
  return (
    <li key={message.text} className={type === 'improvements' ? 'suggestion': ''}>
        <Trans
          i18nKey={`results:${translationKey}.${message.text}`}
          {
            ...(message.items && message.items.length !== 0 ? {values: {items: message.items.map(item => {
              if(item.text) {
                return t(`results:${translationKey}.${item.text}`, {
                  items: item.items.map(i => t(`results:${translationKey}.${i}`)).join(', ')
                })
              } else {
                return t(`results:${translationKey}.${item}`)
              }
            }).join(', ')}} : {})
          }
        />
    </li>
  )
}

function Accordion(props) {
  const { data, group } = props
  const { t } = useTranslation()
  const initialState = data.reduce((acc, curr) => {
    acc[curr.name] = false
    return acc
  }, {})

  const [openSections, setOpenSections] = useState(initialState)

  const toggleOpen = section => {
    setOpenSections({
      ...openSections,
      [section]: !openSections[section]
    })
  }

  return (
    <div className="accordion">
      {data.map(section => {
        const messageElements = Object.entries(section.messages).flatMap(([type, messages]) => {
          return messages.map(message => renderMessage(t, `${group}.${section.name}`, type, message))
        })
        const [firstMessage, ...restMessages] = messageElements

        return (
          <div className="accordion-section" key={section.name}>
            <div className="accordion-nobreak">
              <div className="accordion-header" onClick={() => {toggleOpen(section.name)}}>
                <h3>{t(`results:${group}.${section.name}.title`)}</h3>
                {
                  openSections[section.name] ? (
                    <ChevronUp/>
                  ): (
                    <ChevronDown/>
                  )
                }
              </div>
              <div className={`accordion-content${openSections[section.name] ? '' : ' hidden'}`}>
                <ul>
                    {firstMessage}
                </ul>
              </div>
            </div>
            <div className={`accordion-content${openSections[section.name] ? '' : ' hidden'}`}>
              <ul>
                {
                  restMessages
                }
              </ul>
            </div>
          </div>
        )
      })}
    </div>
  )

}

function QualityResults(props) {
  const { t } = useTranslation()
  const {data, group} = props
  return (
    <div className="group" id={group}>
      <div className="group-header">
        <h2>{t(`results:${group}.title`)}</h2>
      </div>
      <div className="group-content">
        <div className="points">
          <div className="points-outer">
            <div className="points-inner">
              <div className="points-text">
                 <span>{data.points}/{data.max}</span>
              </div> 
              <ThumbsUp />
            </div>
          </div>
        </div>
      </div>
      <Accordion 
        data={data.sections}
        group={group}
      />
    </div>
  )
}

function NoResults(props) {
  const { t } = useTranslation()
  const {returnToForm} = props 
  return (
    <>
      <div className="group" id="noResults">
        <div className="group-header">
          <h2>{t('results:noResults')}</h2>
        </div>
        <div className="group-content">
          <div className="points">
            <div className="points-outer">
              <div className="points-inner">
                <FileText size={43}/>
              </div>
            </div>
          </div>
          <p>{t('results:noResultsText')}</p>
        </div>
      </div>
      <div className="group" id="return">
        <div className="group-content">
          <button className="primary" onClick={returnToForm}>{t('results:return')}</button>
        </div>
      </div>
    </>
  )
}

function ResultsPage(props) {
  const { results, returnToForm } = props
  const { t, i18n } = useTranslation()
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => document.querySelectorAll('.accordion-content').forEach(element => element.classList.toggle('hidden')),
    documentTitle: 'Climpactor'
  });
  
  useEffect(() => {
    if(window.ga) {
      window.ga.pageView('/ilmastolaskuri', 'Tulokset')
    }
  },[])

  return (
    <div className="section" id="results">
      <>
        <div ref={componentRef}>
          {
            Object.keys(results).length > 0 && (
              <div className="group info">
                <div className="group-content">
                  <h2>{t('results:info.title')}</h2>
                  <p>
                    {t('results:info.content')}
                  </p>
                  <a href={t('results:info.link')}>{t('results:info.linkText')}</a>
                  </div>
              </div>
            )
          }
          {
            Object.keys(results).length === 0 ?
              <NoResults returnToForm={returnToForm} />
              : Object.entries(results).map(([group, data]) =>{
                  switch(group) {
                    case 'footprint':
                      return <FootprintResults data={data} key={group} />
                    case 'handprint':
                      return <HandprintResults data={data} key={group} />
                    case 'employment':
                      return <EmploymentResults data={data} key={group} />
                    case 'wellness':
                    case 'health':
                    case 'circularEconomy':
                    case 'competence':
                      return <QualityResults data={data} group={group} key={group} />
                    default:
                      return ''
                  }
                })
          }
          {
            Object.keys(results).length > 0 && (
              <div className="group logos">
                <div className="group-header">
                  <h2>{t('results:collaboration')}</h2>
                </div>
                <div className="group-content">
                  {
                    i18n.language === 'en' ? 
                    (<img src={TeknoEn} alt="Technology Indstries of Finland logo" />) :
                    (<img src={TeknoFi} alt="Teknologiateollisuus logo" />)
                  }
                  <img src={Gaia} alt="Gaia logo" />
                </div>
              </div>
            )
          }
        </div>
        <button className="primary" onClick={() => {
          document.querySelectorAll('.accordion-content').forEach(element => element.classList.toggle('hidden')) //hack for loading the list item images for printing
          handlePrint()
        }}>{t('results:print')}</button>
      </>
    </div>
  )
}


export default ResultsPage
