import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import basicsTranslations from './sections/basics/translations.json'
import leadingTranslations from './sections/leading-questions/translations.json'
import footprintTranslations from './sections/footprint/translations.json'
import circularEconomyTranslations from './sections/circular-economy/translations.json'
import competenceTranslations from './sections/competence-and-innovation/translations.json'
import wellnessTranslations from './sections/wellness-and-equality/translations.json'
import healthTranslations from './sections/health-and-safety/translations.json'
import handprintTranslations from './sections/handprint/translations.json'
import commonTranslations from './common/translations.json'
import resultTranslations from './pages/results/translations.json'
import LanguageDetector from 'i18next-browser-languagedetector'
import introTranslations from './sections/intro/translations.json'
import employmentTranslations from './sections/employment/translations.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: {
      order: ['localStorage']
    },
    ns: ['common', 'basics', 'leading', 'footprint', 'circularEconomy', 'competence','wellness','health','handprint', 'results','intro','employment'],
    defaultNS: 'common',
    fallbackLng: "fi", 
    resources: {
      fi: {
        basics: basicsTranslations.fi,
        leading: leadingTranslations.fi,
        footprint: footprintTranslations.fi,
        circularEconomy: circularEconomyTranslations.fi,
        competence: competenceTranslations.fi,
        wellness: wellnessTranslations.fi,
        health: healthTranslations.fi,
        handprint: handprintTranslations.fi,
        common: commonTranslations.fi,
        results: resultTranslations.fi,
        intro: introTranslations.fi,
        employment: employmentTranslations.fi
      },
      en: {
        basics: basicsTranslations.en,
        leading: leadingTranslations.en,
        footprint: footprintTranslations.en,
        circularEconomy: circularEconomyTranslations.en,
        competence: competenceTranslations.en,
        wellness: wellnessTranslations.en,
        health: healthTranslations.en,
        handprint: handprintTranslations.en,
        common: commonTranslations.en,
        results: resultTranslations.en,
        intro: introTranslations.en,
        employment: employmentTranslations.en
      }
    },
    interpolation: {
      escapeValue: false 
    }
  });

export default i18n