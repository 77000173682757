
export const initialize = (gaCode) => {
  const head = document.getElementsByTagName('head')[0]
  const tagManager = document.createElement('script')
  tagManager.setAttribute('async','')
  tagManager.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${gaCode}`)
  tagManager.onload = () => {

    const initCodeTag = document.createElement('script')
    const initCode = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${gaCode}', { cookie_flags: 'SameSite=None;Secure' });
    `
    initCodeTag.innerHTML = initCode
    head.appendChild(initCodeTag)
    Object.assign(window, {'ga': {
      pageView,
      event
    }})
  }

  head.appendChild(tagManager)

}

export const pageView = (path, title, location) => {
  window.gtag('event', 'page_view', {
    page_path: path,
    page_location: location || window.location,
    page_title: title || document.title
  })

}

export const event = (action, label, category ) => {
  window.gtag('event', action, {
    event_label: label,
    event_category: category,
    non_interaction: false
  })
}