import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import UnitInput from '../UnitInput'
import ToggleUnitInput from '../ToggleUnitInput'
import Tooltip from '../Tooltip'
import { useTranslation } from 'react-i18next'
import ToggleMultiUnitInput from '../ToggleMultiUnitInput'

function GroupForm(props) {
  const {data, name} = props
  return (
    <>
      {
        data.groups.map(group => {
          return <Group group={group} name={name} key={`${name}-${group.name}`} />
        })
      }
    </>
  )
}

GroupForm.propTypes = {
  name: PropTypes.string,
  groups: PropTypes.arrayOf(Group)
}

function Group(props) {
  const {group, name} = props
  const { t } = useTranslation()

  return (
    <div className="group" key={`${name}-${group.name}`}>
      {group.title && (
        <div className="group-header">
          <h2>{t(group.title)}</h2>
          {group.tooltip && (
            <Tooltip 
              id={group.name}
              tooltip={t(group.tooltip)}
            />
          )
          }

        </div>
      )}
      <div className="group-content">
        {
          group.fields.map(field => renderField(field, t))
        }
      </div>
    </div>
  )
}

Group.propTypes = {
  name: PropTypes.string.isRequired,
  group: PropTypes.shape({
    title: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string,
      label: PropTypes.string,
      unit: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string
        ])
      })),
      fields: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        type: PropTypes.string,
        label: PropTypes.string,
        unit: PropTypes.string,
      }))
    }))
  }).isRequired
}

function renderField(field, t) {
  switch (field.type) {
    case 'checkbox-group':
      return (
        <div className="question" key={field.name}>
          <h3>{t(field.label)}</h3>
          <div className="input-group">
            {
              field.options.map(option => {
                return (
                  <label key={option.name} htmlFor={option.name}>
                    <Field type="checkbox" name={option.name} id={option.name}/>
                    <span>{t(option.label)}</span>
                  </label>
                )
              })
            }
          </div>
        </div>
      )
    case 'checkbox':
      return (
        <div className="question" key={field.name}>
          <label htmlFor={field.name}>
            <Field type="checkbox" name={field.name} id={field.name}/>
            <span>{t(field.label)}</span>
          </label>
        </div>
      )
    case 'radio-group': 
      return (
        <div className="question" key={field.name}>
          <h3>{t(field.label)}</h3>
          <div className="input-group">
            {
              field.options.map(option => {
                return(
                  <label key={option.name} htmlFor={option.name}>
                    <Field type="radio" name={field.name} id={option.name} value={option.value} />
                    <span>{t(option.label)}</span>
                  </label>
                )
              })
            }
          </div>
        </div>
      )
    case 'unit-text': 
      return (
        <div className="unit-text" key={field.name}>
          <UnitInput
            name={field.name}
            label={t(field.label)}
            unit={t(field.unit)}
            tooltip={t(field.tooltip)}
          />
        </div>
      )
    case 'text':
      return (
        <div className="question" key={field.name}>
          <UnitInput
            name={field.name}
            label={t(field.label)}
            unit={t(field.unit)}
            tooltip={t(field.tooltip)}
          />
        </div>
      )
    case 'toggle-text':
      return (
        <ToggleUnitInput
          name={field.name}
          label={t(field.label)}
          unit={t(field.unit)}
          key={field.name}
          tooltip={t(field.tooltip)}
         />
      )
    case 'toggle-group': {
      const [first, ...rest] = field.fields

      return (
        <ToggleMultiUnitInput
          name={first.name}
          label={t(first.label)}
          unit={t(first.unit)}
          key={first.name}
    
         >
          {
            rest.map(input => (
              <UnitInput
                name={input.name}
                label={t(input.label)}
                unit={t(input.unit)}
                key={input.name}
              />
            ))
          }
        </ToggleMultiUnitInput>
      )

    
    }
    case 'select':
      return (
        <div className="question" key={field.name}>
          <label className={`select-label${field.tooltip? ' label-tooltip': ''}`} htmlFor={field.name}>
            {t(field.label)}
            {field.tooltip && <Tooltip tooltip={t(field.tooltip)} id={field.name} />}
          </label>
          <Field 
            name={field.name} 
            as='select'
          >
            {
              field.options && (
                <option key="default" value={""} defaultValue>{t('common:choose')}</option>
              )
            }
            {field.options && field.options.map(option => {
              return (
                <option key={option.value} value={option.value}>{t(option.label)}</option>
              )
            })}
          </Field>
        </div>
      )
    default:
      return (
        <div>{field.type} not implemented</div>
      )
  }
}


export default GroupForm
export {
  Group
}

