import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import FuelModal from '../../sections/footprint/fuel-modal'
import { PlusCircle, X } from 'react-feather'
import UnitInput from '../UnitInput'
import { useTranslation } from 'react-i18next'


function Fuel(props) {
  const {setFieldValue} = useFormikContext()
  const { t } = useTranslation()

  const [showFuelModal, setShowFuelModal] = useState(false)
  const {
    selectedFuels,
    setSelectedFuels,
    name,
    prefix,
  } = props

  const handleDelete = fuel => {
    setSelectedFuels({
      ...selectedFuels,
      [name]: selectedFuels[name].filter(value => value !== fuel)
    })
    setFieldValue(`${prefix}.${fuel}`, '')
  }

  return (
    <>
      {showFuelModal && (
        <FuelModal 
          selectedFuels={selectedFuels[name]} 
          close={() => {setShowFuelModal(false)}} 
          select={(fuels) => {
            //clear inputs from fuels that were previosuly selected but are not anymore
            selectedFuels[name].filter(value => !fuels.includes(value)).forEach(fuel => setFieldValue(`${prefix}.${fuel}`, ''))

            setSelectedFuels({
              ...selectedFuels,
              [name]: fuels
            })
            setShowFuelModal(false)
          }}
          />
      )}

      <div className="group">
        <div className="group-header">
          <h2>{t('common:fuel.title')}</h2>
        </div>
        <div className="group-content">
          <div className="add-fuel">
            <h3>{t('common:fuel.add-title')}</h3>
            <button type="button" onClick={() => {setShowFuelModal(true)}} > <PlusCircle/> {t('common:fuel.add')}</button>
          </div>
        {
          selectedFuels[name].length !== 0 && (
            selectedFuels[name].map(selectedFuel => {
              const label = `${t(`common:fuel.${selectedFuel}`)}`
              return (
                <div className="fuel" key={selectedFuel}>
                  <UnitInput 
                      name={`${prefix}.${selectedFuel}`}
                      label={label}
                      unit={selectedFuel === 'natural-gas' ? t('common:fuel.cubicUnit') : t('common:fuel.tons')}
                  />
                  <span className="delete" onClick={() => {handleDelete(selectedFuel)}}><X /></span>
                </div>
              )
            })
          )
        }
        </div>
      </div>
    </>
  )
}

Fuel.propTypes = {
  selectedFuels: PropTypes.object.isRequired,
  setSelectedFuels: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  prefix: PropTypes.string.isRequired
}
export default Fuel