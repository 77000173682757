import React, { useState } from 'react'
import { ErrorMessage, Field, getIn, useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

function ToggleMultiUnitInput(props) {
  const { setFieldValue, values } = useFormikContext()
  const { t } = useTranslation()
  const {
    name,
    label,
    unit,
    children
  } = props
  const { errors } = useFormikContext()

  const initialState = Boolean(getIn(values, name))
  const [showInput, setShowInput] = useState(initialState)

  const handleChange = event => {
    const checked = event.target.checked
    if(!checked) {
      setFieldValue(name, '')
      children.forEach(child => {
        setFieldValue(child.props.name, '')
      })
    }
    setShowInput(checked)
  }

  return (
    <div className={`toggle-unit-input${showInput ? ' open': ''}`}>
      <label htmlFor={`toggle-${name}`} >
        <input type="checkbox" id={`toggle-${name}`} name={`toggle-${name}`} onChange={handleChange} checked={showInput}/>
        <span>{label}</span>
      </label>
      {
        showInput && (
          <>
            <div className="unit-input">
              <div className={`unit-input-container${getIn(errors, name) ? ' errors': ''}`}>
              <Field type="text" name={name} id={name} maxLength="35"/>
              {
                unit && (
                  <span className="unit">{unit}</span>
                )
              }
              </div>
              <ErrorMessage name={name} >{msg => <div className="errors">{t(msg)}</div>}</ErrorMessage>
            </div>
            {children}
          </>
        )
      }
    </div>
  )
}

ToggleMultiUnitInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  unit: PropTypes.string,
}
export default ToggleMultiUnitInput