import React, { useEffect } from 'react'
import sectionData from './data.json'
import GroupForm from '../../components/groupform'
import { Trans, useTranslation } from 'react-i18next'

function HealthandSafety() {
  const { t } = useTranslation()

  useEffect(() => {
    if(window.ga) {
      window.ga.pageView('/ilmastolaskuri', 'Terveys ja turvallisuus')
    }
  },[])

  return (
    <>
      <div className="section">
        <div className="group info">
          <div className="group-content">
            <h2>{t('health:info.title')}</h2>
            <Trans i18nKey={`health:info.text`} />
          </div>
        </div>
        <GroupForm data={sectionData} name="healthAndSafety" />
      </div>
    </>
  )
}

export default HealthandSafety

export {
  sectionData as healthSectionData
}