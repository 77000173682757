const initialValues = {
  footprint: {
    fuels: {
      gasoline: '',
      "light-oil": '',
      diesel: '',
      "heavy-oil": '',
      coal:'',
      "natural-gas": '',
      "liquified-natural-gas": '',
      pellets: '',
      wood: '',
      "other-sedimentary-fuels": '',
      biogas: '',
      biofuel: '',
      "recycled-fuels": '',
      "other-mixed-fuels": ''
    },
    purchases: {
      energy: {
        electricity: {
          value: '',
          percent: '',
          multiplier: ''
        },
        heating: {
          value: '',
          percent: '',
          multiplier: '',
        },
        cooling: {
          value: '',
          percent: '',
          multiplier: '',
        },
        steam: {
          value: '',
          multiplier: '',
        },
      },
      "purchases-part1": {
        materials: {
          electronics: '',
          mining: '',
          chemistry: '',
          "metal-and-machines": '',
          metal: '',
          forestry: '',
          other: ''
        },
        services: {
          energy: '',
          admin: '',
          maintenance: '',
          sanitation: '',
          "business-travel": '',
          finance: '',
          construction: '',
          planning: '',
          communication: '',
          health: '',
          it: '',
          storage: ''
        },
        logistics: {
          air: '',
          road: '',
          rail: '',
          water: ''
        }
      },
      "purchases-part2": {
        materials: {
          aluminum: '',
          steel: '',
          copper: '',
          glass: '',
          plastic: '',
          concrete: ''
        },
        logistics: {
          van: '',
          truck: '',
          "semi-trailer": '',
          trailer: '',
          rails: '',
          "container-ship": '',
          "roro-ship": '',
          aviation: {
            domestic: '',
            foreign: '',
            "long-haul": ''
          }
        },
        "corporate-travel": {
          aviation: {
            domestic: '',
            foreign: '',
            "long-haul": ''
          },
          car: '',
          bus: '',
          "foreign-train": '',
          ship: '',
          hotel: '',
        },
        commuting: {
          personnel: '',
          "work-days": '',
          remote: '',
          car: '',
          "car-estimate": '',
          bus: '',
          "bus-estimate": '',
          emissionless: '',
          "emissionless-estimate": ''
        },
        sanitation: {
          landfill: '',
          burning: '',
          bio: '',
          paper: '',
          cardboard: '',
          glass: '',
          metal: '',
          plastic: '',
          wood: '',
          energy: '',
          construction: '',
          ser: '',
          dangerous: '',
          wastewater: ''
        },
        processing: {
          electricity: ''
        },
        usage: {
          electricity: '',
          gasoline: '',
          "light-oil": '',
          diesel: '',
          "heavy-oil": '',
          coal:'',
          "natural-gas": '',
          "liquified-natural-gas": '',
          pellets: '',
          wood: '',
          "other-sedimentary-fuels": '',
          biogas: '',
          biofuel: '',
          "recycled-fuels": '',
          "other-mixed-fuels": ''
        },
        disposal: {
          landfill: '',
          burning: '',
          bio: '',
          paper: '',
          cardboard: '',
          glass: '',
          metal: '',
          plastic: '',
          wood: '',
          energy: '',
          construction: '',
          ser: '',
          dangerous: ''
        }
      }
    }
  }
}

export default initialValues