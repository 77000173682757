import React, { useState } from 'react'
import { ChevronDown } from 'react-feather'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

function LanguageSelector() {
  const { i18n } = useTranslation()
  const options = [
    { value: 'fi', label: 'FI' },
    { value: 'en', label: 'EN' },
  ]
  const initialLanguage = options.find(lang => lang.value === i18n.language)

  const [selectedLanguage, setSelectedLanguage] = useState(initialLanguage)

  const styles = {
    control:  (provided) => ({
      ...provided,
      border: 'none',
      background: 'transparent',
      boxShadow: 'none',
      width: '70px',
      cursor: 'pointer',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#000000'
    }),
    option: (provided, state) => ({
      ...provided,
      color: '#000000',
      backgroundColor: state.isSelected ? '#ff805c': '#ffffff',
      cursor: 'pointer',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#000000',
      '&:hover': {
        color: '#000000',
      }
    }),
  }
  const handleOnChange = value => {
    localStorage.setItem('i18nextLng', value.value)
    i18n.changeLanguage(value.value)
    setSelectedLanguage(value)
  }

  return (
    <Select
      options={options}
      styles={styles}
      isSearchable={false}
      value={selectedLanguage}
      onChange={(value) => handleOnChange(value)}
      components={{
        IndicatorSeparator: () => null,
        DownChevron: () => <ChevronDown/>
      }}
    />
  )

}

export default LanguageSelector
