import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import './style.css'

function IntroSection() {
  const { t } = useTranslation()

  useEffect(() => {
    if(window.ga) {
      window.ga.pageView('/ilmastolaskuri', 'Johdanto')
    }
  },[])

  return (
    <div className="section" id="intro">
      <div className="group">
        <div className="group-header">
          <h2>{t('intro:added-value.title')}</h2>
        </div>
        <div className="group-content">
          <Trans i18nKey='intro:added-value.content' />
        </div>
      </div>
      <div className="group">
        <div className="group-header">
          <h2>{t('intro:tool.title')}</h2>
        </div>
        <div className="group-content">
          <Trans i18nKey="intro:tool.content" />
          <div className="note">
              <h2>{t('intro:note.title')}</h2>
              <Trans i18nKey='intro:note.content'/>
              <p>
                {t('intro:note.read-more')}<br/>
                <a href={t('intro:note.link')}>{t('intro:note.link')}</a>
              </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IntroSection