import basicsSchema from '../sections/basics/schema'
import footprintSchema from '../sections/footprint/schema'
import employmentSchema from '../sections/employment/schema'
import * as Yup from 'yup'

const schema = Yup.object().shape({
  basics: basicsSchema,
  footprint: footprintSchema,
  employment: employmentSchema,
})

export default schema