import React, { useEffect } from 'react'
import sectionData from './data.json'
import GroupForm from '../../components/groupform'
import { Trans, useTranslation } from 'react-i18next'


function CompetenceAndInnovation() {
  const { t } = useTranslation()
  useEffect(() => {
    if(window.ga) {
      window.ga.pageView('/ilmastolaskuri', 'Osaaminen ja innovaatiot')
    }
  },[])

  return (
    <>
      <div className="section">
        <div className="group info">
          <div className="group-content">
            <h2>{t('competence:info.title')}</h2>
            <Trans i18nKey={`competence:info.text`} />
          </div>
        </div>
        <GroupForm data={sectionData} name="competenceAndInnovation" />
      </div>
    </>
  )
}

export default CompetenceAndInnovation

export {
  sectionData as competenceSectionData
}