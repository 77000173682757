import * as Yup from 'yup'

const numberBase = Yup.number().transform((_value, original) => Number(original.replace(/,/,'.'))).typeError('common:errors.number')
const numberBaseRequired = Yup.number().transform((_value, original) => original === '' ? undefined: Number(original.replace(/,/,'.'))).typeError('common:errors.number').required('common:errors.required')
const positiveNumber = numberBase.min(0, 'common:errors.positive')
const negativeNumber = numberBase.max(0, 'common:errors.negative')
const positiveNumberRequired = numberBaseRequired.min(0, 'common:errors.positive')
const negativeNumberRequired = numberBaseRequired.max(0, 'common:errors.negative')
const positivePercentage = numberBase.min(0, 'common:errors.positivePercentage').max(100, 'common:errors.positivePercentage')
const positivePercentageRequired = numberBaseRequired.min(0, 'common:errors.positivePercentage').max(100, 'common:errors.positivePercentage')
const negativePercentage = numberBase.min(-100, 'common:errors.negativePercentage').max(0, 'common:errors.negativePercentage')
const negativePercentageRequired = numberBaseRequired.min(-100, 'common:errors.negativePercentage').max(0, 'common:errors.negativePercentage')

export {
  numberBase,
  positiveNumber,
  positiveNumberRequired,
  negativeNumber,
  negativeNumberRequired,
  positivePercentage,
  positivePercentageRequired,
  negativePercentage,
  negativePercentageRequired
}