import React, { useRef, useState } from 'react'
import {Bar} from 'react-chartjs-2'


function CustomBar(props) {
  const ref = useRef(null)
  const [legend, setLegend] = useState(false)
  const plugins = [{
    afterDatasetsDraw() {
      if (!legend) {
        setLegend(true)
      }
    }
  }]


  const options = {
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
        }
      }]
    },
    legend: {
      display: false
    }
  }
  return (
    <>
        <Bar {...props} plugins={plugins} ref={ref} options={options}/>
        {
          legend && (
            <ul className="graph-legend">
              {
                ref.current.chartInstance.legend.legendItems.map(item => {
                  return (
                    <li key={item.text}>
                      <div className="legend-icon" style={{backgroundColor: item.fillStyle}} />
                      {item.text}
                    </li>
                  )
                })
              }
            </ul>
          )
        }
    </>
  )
}

export default CustomBar