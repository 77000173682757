import React, { useEffect } from 'react'
import sectionData from './data.json'
import GroupForm from '../../components/groupform'
import { Trans, useTranslation } from 'react-i18next'

function Employment() {
  const { t } = useTranslation()

  useEffect(() => {
    if(window.ga) {
      window.ga.pageView('/ilmastolaskuri', 'Työllisyys')
    }
  },[])

  return (
    <>
      <div className="section" id="employment">
        <div className="group info">
          <div className="group-content">
            <h2>{t('employment:info.title')}</h2>
            <Trans i18nKey={`employment:info.text`} />
          </div>
        </div>
        <GroupForm data={sectionData} name="employment" />
      </div>
    </>
  )
}

export default Employment
