import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { PlusCircle, X } from 'react-feather'
import HandprintModal from './handprint-modal'
import './handprint.css'
import ConfirmModal from '../../components/Modal'
import replacementOptions from './replacementData'
import { Trans, useTranslation } from 'react-i18next'

function HandPrint(props) {
  const {solutions, setSolutions} = props
  const [showModal, setShowModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const { t } = useTranslation()
  const handleDelete = index => {
    setSolutions(solutions.filter((value, idx) => index !== idx))
  }

  useEffect(() => {
    if(window.ga) {
      window.ga.pageView('/ilmastolaskuri', 'Asiakkaan prosessit')
    }
  },[])

  return (
    <>
      {
        showConfirmModal && (
          <ConfirmModal
            content={t('common:resetSingleForm.text')}
            title={t('common:resetSingleForm.title')}
            close={() => {setShowConfirmModal(false)}}
            confirm={() => {
              setSolutions([])
              setShowConfirmModal(false)
            }}
          />
        )
      }
      {showModal && (
        <HandprintModal
          close={() => setShowModal(false)}
          select={(solution) => {
            solutions.push(solution)
            setSolutions(solutions)
            setShowModal(false)
          }}
        />
      )}
      <div className="section" id="handprint">
        <div className="group info">
          <div className="group-content">
            <h2>{t('handprint:info.title')}</h2>
            <Trans i18nKey={`handprint:info.text`} />
          </div>
        </div>
        <div className="group">
          <div className="group-header">
            <h2>
              {t('handprint:title')}
            </h2>
          </div>
          <div className="group-content">
            <div className="add-solution">
              <h3>{t('handprint:subtitle')}</h3>
              <button type="button" onClick={() => setShowModal(true)}>
                <PlusCircle /> {t('handprint:addSolution')}
              </button>
            </div>
          </div>
        </div>
        {
          solutions.map((solution, index) => {
          const {
              unit: replacementUnit = ''
            } = solution.replacement ? replacementOptions[solution.type].find(item => item.value === solution.replacement) || {} : {}

            let increaseUnit = ''
            if(solution.increase) {
              const [type, ...rest] = solution["increase-type"].split('.')
              if(type) {
                const increaseTypeValue = rest.join('.')
                const data = replacementOptions[type].find(item => item.value === increaseTypeValue)
                if(data) {
                  increaseUnit = data.unit || ''
                }
              }
            }
            return (
              <div className="group" key={solution['short-description']}>
                <div className="group-header">
                  <div className="solution-header">
                    <h2>{`${t('handprint:solution')} ${index+1}`}</h2>
                    <span className="delete" onClick={() => handleDelete(index)}>
                      <X />
                    </span>
                  </div>
                </div>
                <div className="group-content">
                  <div className="solution">
                    <h3>{t('handprint:shortDescription')}</h3>
                    <p>{solution['short-description']}</p>
                    <h3>{t('handprint:desc')}</h3>
                    <p>{solution.description}</p>
                    <h3>{t('handprint:type.label')}</h3>
                    <p>{t(`handprint:type.options.${solution.type}`)}</p>
                    <h3>{t('handprint:replacement.label')}</h3>
                    <p>{t(`handprint:${solution.type}.${solution.replacement}`)}</p>
                    <h3>{t('handprint:consumption')}</h3>
                    <p>{solution.consumption} {replacementUnit}</p>
                    <h3>{t('handprint:change')}</h3>
                    <p>{solution.change} %</p>
                    {
                      solution.increase && (
                        <>
                          <h3>{t('handprint:increaseType.label')}</h3>
                          <p>{t(`handprint:${solution['increase-type']}`)}</p>
                          <h3>{t('handprint:increaseAmount')}</h3>
                          <p>{solution['increase-amount']} {increaseUnit}</p>
                        </>
                      )
                    }
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    </>
  )
}
HandPrint.propTypes = {
  solutions: PropTypes.arrayOf(PropTypes.shape({
    'short-description': PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    replacement: PropTypes.string,
    change: PropTypes.string,
    consumption: PropTypes.string,
    'increase-type': PropTypes.string,
    'increase-amount': PropTypes.string,
    increase: PropTypes.bool
  })),
  setSolutions: PropTypes.func.isRequired
}

export default HandPrint