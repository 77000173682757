import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { X } from 'react-feather'
import * as Yup from 'yup'
import { positiveNumber, positiveNumberRequired, negativePercentageRequired } from '../../common/schema-helpers'
import replacementOptions from './replacementData'
import { useTranslation } from 'react-i18next'

const typeOptions = [
  'fuel',
  'energy',
  'materials',
  'travel',
  'purchases'
]


const schema = Yup.object({
  consumption: positiveNumberRequired,
  change: negativePercentageRequired,
  'replacement': Yup.string().required('common:errors.required'),
  'short-description': Yup.string().required('common:errors.required'),
  'type': Yup.string().required('common:errors.required'),
  'increase': Yup.boolean(),
  'increase-type': Yup.string().when('increase', {
    is: true,
    then: Yup.string().required('common:errors.required'),
    otherwise: Yup.string()
  }),
  'increase-amount': positiveNumber.when('increase', {
    is: true,
    then: positiveNumberRequired,
    otherwise: positiveNumber
  }),


}) 

function HandprintModal(props)  {
  const { t } = useTranslation()
  const {close, select} = props
  const [solution, setSolution] = useState({
    type: '',
    replacement: '',
    'short-description': '',
    description: '',
    consumption: '',
    change: '',
    increase: false,
    'increase-amount': '',
    'increase-type': ''
  })

  const validateFields = ['consumption', 'change', 'increase-type', 'short-description', 'type', 'increase-amount', 'replacement']
  const [errors, setErrors] = useState({})
  function validateForm(values) {
    let errors = {}
    Object.keys(values).forEach((key) => {
      if(validateFields.includes(key)){
        try {
          schema.validateSyncAt(key, values, {abortEarly:false, stripUnknown: true})
        } catch(err) {
          if(err instanceof Yup.ValidationError) {
            errors[key] = err.errors
          }
        }
      }

    })

    return errors

  }
  function handleSave(solution) {
    const errors = validateForm(solution)
    if(Object.keys(errors).length !== 0) {
      setErrors(errors)
    } else {
      select(solution)
    }

  }
  function handleChange(event) {
    let newSolution = {
      ...solution,
      [event.target.name]: event.target.value
    }

    if (event.target.name === 'type') {
      newSolution = {
        ...solution,
        replacement: '',
        [event.target.name]: event.target.value
      }
    }
    if(event.target.name === 'increase') {
      if(event.target.checked) {
        newSolution = {
          ...solution,
          'increase': true
        }
      }
      else {
        newSolution = {
          ...solution,
          'increase': false,
          "increase-amount": '',
          "increase-type": ''
        }
      }
    }

    // only validate these fields
    if(validateFields.includes(event.target.name)){
      try {
        schema.validateSyncAt(event.target.name, newSolution, {abortEarly:false, stripUnknown: true})
        setErrors({...errors, [event.target.name]: null})
      } catch(err) {
        if(err instanceof Yup.ValidationError) {
          setErrors({...errors, [event.target.name]: err.errors})
        }
      }
    }
    setSolution(newSolution) 
  }
  const {
    unit: replacementUnit = ''
  } = solution.replacement ? replacementOptions[solution.type].find(item => item.value === solution.replacement) || {} : {}

  let increaseUnit = ''
  if(solution.increase) {
    const [type, ...rest] = solution["increase-type"].split('.')
    if(type) {
      const increaseTypeValue = rest.join('.')
      const data = replacementOptions[type].find(item => item.value === increaseTypeValue)
      if(data) {
        increaseUnit = data.unit || ''
      }
    }
  }

  const isValid = Object.values(errors).every(error => error === null)

  return (
    <div className="modal" id="modal">
      <div className="modal-container">
        <div>
          <div className="modal-header">
            <h2>{t('handprint:modal-title')}</h2>
            <button type="button" className="close" onClick={close} ><X /></button>
          </div>
          <div className="modal-content">
            <div className="modal-group">
              <label htmlFor="type">
                {t('handprint:type.label')}
              </label>
                <select name="type" id="type" onChange={handleChange} value={solution.type} >
                  <option value="">{t('handprint:type.default')}</option>
                {
                  typeOptions.map(option => {
                    return (
                      <option value={option} key={option}>{t(`handprint:type.options.${option}`)}</option>
                    )
                  })
                } 
                </select>
                {
                  errors.type&& (
                    <div className="errors">{t(errors.type)}</div>
                  )
                }
                <label htmlFor="short-description"> 
                  {t('handprint:shortDescription')}
                </label>
                <input type="text" name="short-description" id="short-description" value={solution['short-description']} onChange={handleChange} />
                {
                  errors['short-description']&& (
                    <div className="errors">{t(errors['short-description'])}</div>
                  )
                }
                <label htmlFor="description">
                  {t('handprint:description')}
                </label>
                <textarea name="description" rows="10" id="description" value={solution.description} onChange={handleChange} />
              </div>
            {
              solution['short-description'] && (
                <>
                  <div className="modal-group">
                    <label htmlFor="replacement">
                      {t('handprint:replacement.label')}
                    </label>
                    <select name="replacement" value={solution.replacement} id="replacement" onChange={handleChange}>
                      <option value="" >{t('handprint:replacement.default')}</option>
                      {
                        replacementOptions[solution.type] && replacementOptions[solution.type].map(replacement => {
                          return  (
                            <option value={replacement.value} key={replacement.value}>{t(`handprint:${solution.type}.${replacement.value}`)}</option>
                          )
                        })
                      }
                    </select>
                    {
                      errors.replacement && (
                        <div className="errors">{t(errors.replacement)}</div>
                      )
                    }
                    <div className="unit-input">
                      <label htmlFor="consumption">
                        {t('handprint:consumption')}
                      </label>
                      <div className="unit-input-container">
                        <input type="text" name="consumption" id="consumption" value={solution.consumption} onChange={handleChange} maxLength="25"/>
                        <span className="unit">{replacementUnit}</span>
                      </div>
                      {
                        errors.consumption && (
                          <div className="errors">{t(errors.consumption)}</div>
                        )
                      }
                    </div>
                  </div>
                  <div className="modal-group">
                      <div className="unit-input">
                        <label htmlFor="change">
                          {t('handprint:change')}
                        </label>
                        <div className="unit-input-container">
                          <input type="text" name="change" id="change" value={solution.change} onChange={handleChange} maxLength="25" />
                          <span className="unit">%</span>

                        </div>
                        {
                          errors.change && (
                            <div className="errors">{t(errors.change)}</div>
                          )
                        }
                      </div>
                      <label htmlFor="increase">
                        <input type="checkbox" value={solution.increase} onChange={handleChange} name="increase" id="increase" />
                        <span>{t('handprint:increase')}</span>
                      </label>
                      {
                        solution.increase && (
                          <>
                            <select value={solution['increase-type']} onChange={handleChange} name="increase-type" id="increase-type" >
                              <option value="">{t('handprint:increaseType.default')}</option>
                              {
                                  Object.keys(replacementOptions).reduce((acc, curr) => {
                                    return acc.concat(replacementOptions[curr].map(item => ({value: `${curr}.${item.value}`, label: t(`handprint:${curr}.${item.value}`)})))
                                  }, [] ).map(replacement => {
                                  return  (
                                    <option value={replacement.value} key={replacement.value}>{replacement.label}</option>
                                  )
                                })
                              }
                            </select>
                            {
                              errors['increase-type'] && (
                                <div className="errors">{t(errors['increase-type'])}</div>
                              )
                            }
                          </>
                        )
                      }
                      {
                        solution.increase && (
                          <div className="unit-input">
                            <label htmlFor="increase-amount">
                              {t('handprint:increaseAmount')}
                            </label>
                            <div className="unit-input-container">
                              <input type="text" name="increase-amount" value={solution["increase-amount"]} id="increase-amount" onChange={handleChange} maxLength="25" />
                              <span className="unit">{increaseUnit}</span>
                            </div>
                            {
                              errors['increase-amount'] && (
                                <div className="errors">{t(errors['increase-amount'])}</div>
                              )
                            }
                          </div>
                        )
                      }
                    </div>
                </>
              )
            }
          </div>
        </div>
        <div className="modal-footer">
            <button type="button" className="cancel" onClick={close}>{t('handprint:cancel')}</button>
            <button type="button" className="primary" disabled={!isValid} onClick={() => {handleSave(solution)}}>{t('handprint:add')}</button>
        </div>
      </div>
    </div>
  )
}

HandprintModal.propTypes = {
  close: PropTypes.func.isRequired,
  select: PropTypes.func.isRequired
}

export default HandprintModal