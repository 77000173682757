import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Logo from '../../common/logo.svg'
import LanguageSelector from '../languageSelector'
import { useTranslation } from 'react-i18next'

function Nav(props) {
  const {steps, activeStep, setActiveStep, setOpenNav} = props
  const { t } = useTranslation()
  const handleClick = (index) => {
    setActiveStep(index)
    setOpenNav(false)
  }

  return (
    <nav>
      <div className="logo">
        <Link to="/">
          <img src={Logo} alt="Climpactor" />
        </Link>
        <LanguageSelector />
      </div>
      <ul>
        {
          steps.map((step, index) => {
            return (
              <li className={index === activeStep ? 'active':''} key={step.name}>
                <button type="button" onClick={() => handleClick(index)}>{t(`common:nav.${step.name}`)}</button>
              </li>
            )
          })
        }
      </ul>
    </nav>
  )
}

Nav.propTypes = {
  steps: PropTypes.array,
  activeStep: PropTypes.number,
  setActiveStep: PropTypes.func.isRequired,
  setOpenNav: PropTypes.func.isRequired,
}

export default Nav