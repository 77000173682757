import React from 'react'
import PropTypes from 'prop-types'
import { Menu } from 'react-feather'
import { useTranslation } from 'react-i18next'


function Header(props) {
  const { t } = useTranslation()
  const { openNav} = props

  return(
    <div className="header">
      <Menu  onClick={() => openNav(true)}/>
      <h3>{t('common:menu')}</h3>
    </div>
  )

}

Header.propTypes = {
  openNav: PropTypes.func.isRequired
}
export default Header