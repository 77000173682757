import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './modal.css'
import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'

const fuels = [
  'gasoline',
  'light-oil',
  'diesel',
  'heavy-oil',
  'coal',
  'natural-gas',
  'liquified-natural-gas',
  'pellets',
  'wood',
  'other-sedimentary-fuels',
  'biogas',
  'biofuel',
  'recycled-fuels',
  'other-mixed-fuels',
]


function FuelModal(props) {
  const { t } = useTranslation()
  const {selectedFuels, close, select} = props
  const [selectedInputs, setSelectedInputs] = useState(selectedFuels)

  function addOrRemoveFuel(event) {
    const newFuels = [...selectedInputs]
    const index = newFuels.findIndex((value) => value === event.target.name)
    if (index === -1) {
      newFuels.push(event.target.name)
    }
    else {
      newFuels.splice(index, 1)
    }
    setSelectedInputs(newFuels)
  } 

  return (
    <div id="modal" className="modal" onClick={close}>
      <div className="modal-container" onClick={(e) => e.stopPropagation()}>
        <div>
          <div className="modal-header">
            <h2>{t('common:fuel.select')}</h2>
            <button type="button" className="close" onClick={close} ><X /></button>
          </div>
          <div className="modal-content">
            {
              fuels.map(fuel => {
                return {
                  value: fuel,
                  label: t(`common:fuel.${fuel}`)
                }
              }).sort((a, b) => a.label.localeCompare(b.label)).map(fuel => {
                return (
                  <label htmlFor={fuel.value} key={fuel.value}>
                    <input 
                      type="checkbox" 
                      name={fuel.value} 
                      id={fuel.value} 
                      checked={selectedInputs.includes(fuel.value)}
                      onChange={addOrRemoveFuel}/> <span>{fuel.label}</span>
                  </label>
                )
              })
            }
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="cancel" onClick={close}>{t('footprint:cancel')}</button>
          <button type="button" className="primary" onClick={() => {select(selectedInputs)}}>{t('footprint:add')}</button>
        </div>
      </div>
    </div>
  )
}

FuelModal.propTypes = {
  selectedFuels: PropTypes.array,
  close: PropTypes.func.isRequired,
  select: PropTypes.func.isRequired
}

export default FuelModal