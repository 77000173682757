import * as Yup from 'yup'
import { positiveNumber } from '../../common/schema-helpers'

export default Yup.object().shape({
  'company': Yup.object().shape({
    personnel: positiveNumber,
    'corporate-tax': positiveNumber,
    'income-tax': positiveNumber
  }),
  'purchases': Yup.object().shape({
    materials: Yup.object().shape({
      electronics: positiveNumber,
      mining: positiveNumber,
      chemistry: positiveNumber,
      "metal-and-machines": positiveNumber,
      metal: positiveNumber,
      forestry: positiveNumber,
      other: positiveNumber
    }),
    services: Yup.object().shape({
      energy: positiveNumber,
      admin: positiveNumber,
      maintenance: positiveNumber,
      sanitation: positiveNumber,
      "business-travel": positiveNumber,
      finance: positiveNumber,
      construction: positiveNumber,
      planning: positiveNumber,
      communication: positiveNumber,
      health: positiveNumber,
      it: positiveNumber,
      storage: positiveNumber
    }),
    logistics: Yup.object().shape({
      air: positiveNumber,
      road: positiveNumber,
      rail: positiveNumber,
      water: positiveNumber
    })
  }),
})