import { Field, useFormikContext } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'


function PurchaseSelector(props) {
  const {setSteps} = props
  const {setFieldValue} = useFormikContext()
  const { t } = useTranslation()

  const handleChange = (event, step) => {
    setSteps(step)
    setFieldValue(event.target.name, event.target.value)
  }

  return (
    <div className="group">
      <div className="group-header">
      </div>
      <div className="group-content">
        <div className="question">
          <h3>{t('footprint:selector.label')}</h3>
          <div className="input-group">
            <label htmlFor={'footprint.selector.euro'}>
              <Field type="radio" name={'footprint.purchases.selector'} id='footprint.selector.euro' 
                value="euro"
                onChange={(event) => handleChange(event, 'purchases.purchases-part1')}
              />
              <span>{t('footprint:selector.euro')}</span>
            </label>
            <label htmlFor={'footprint.selector.precision'}>
              <Field type="radio" name={'footprint.purchases.selector'} id="footprint.selector.precision" 
                value="precision" 
                onChange={(event) => handleChange(event, 'purchases.purchases-part2')}
              />
              <span>{t('footprint:selector.precision')}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PurchaseSelector