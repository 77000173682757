import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {Field, useFormikContext} from 'formik'
import './leading-questions.css'
import { useTranslation } from 'react-i18next'

//TODO: clear footprint/handprint selection if their parent is unset
function LeadingQuestions(props) {
  const { t } = useTranslation()
  const { setFieldValue } = useFormikContext()
  const { setSteps } = props

  const handleChange = (event, step) => {
    setSteps(step)
    setFieldValue(event.target.name, event.target.checked)
  }

  useEffect(() => {
    if(window.ga) {
      window.ga.pageView('/ilmastolaskuri', 'Näkökulmien valinta')
    }
  },[])

  return (
    <>
      <div className="section" id="leading-questions">
        <div className="group">
          <div className="group-header">
            <h2>{t('leading:title')}</h2>
          </div>
          <div className="group-content">
            <div className="question">
              <div className="input-group">
                <h3>{t('leading:aspect.footprint-impacts')}</h3>
                <div className="input-subgroup">
                  <label htmlFor="leading.aspect.resources">
                    <Field type="checkbox" name="leading.aspect.resources" id="leading.aspect.resources" onChange={(event) => handleChange(event, 'footprint')} />
                    <span>{t('leading:aspect.resources')}</span>
                  </label>
                </div>
              </div>
              <div className="input-group">
                <h3>
                  {t('leading:aspect.handprint-impacts')}
                </h3>
                <div className="input-subgroup">
                  <label htmlFor="leading.aspect.handprint">
                    <Field type="checkbox" name="leading.aspect.handprint" id="leading.aspect.handprint" onChange={(event) => handleChange(event, 'handprint')} />
                    <span>{t('leading:aspect.handprint')}</span>
                  </label>
                  <label htmlFor="leading.aspect.employment">
                    <Field type="checkbox" name="leading.aspect.employment" id="leading.aspect.employment" onChange={(event) => handleChange(event, 'employment')} />
                    <span>{t('leading:aspect.employment')}</span>
                  </label>
                  <label htmlFor="leading.aspect.circular-economy">
                    <Field type="checkbox" name="leading.aspect.circular-economy" id="leading.aspect.circular-economy" onChange={(event) => handleChange(event, 'circularEconomy')} />
                    <span>{t('leading:aspect.circular-economy')}</span>
                  </label>
                  <label htmlFor="leading.aspect.wellness">
                    <Field type="checkbox" name="leading.aspect.wellness" id="leading.aspect.wellness" onChange={(event) => handleChange(event, 'wellness')} />
                    <span>{t('leading:aspect.wellness')}</span>
                  </label>
                  <label htmlFor="leading.aspect.health">
                    <Field type="checkbox" name="leading.aspect.health" id="leading.aspect.health" onChange={(event) => handleChange(event, 'health')} />
                    <span>{t('leading:aspect.health')}</span>
                  </label>
                  <label htmlFor="leading.aspect.competence">
                    <Field type="checkbox" name="leading.aspect.competence" id="leading.aspect.competence" onChange={(event) => handleChange(event, 'competence')} />
                    <span>{t('leading:aspect.innovations')}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

LeadingQuestions.propTypes = {
  setSteps: PropTypes.func.isRequired
}

export default LeadingQuestions